function setGdprCookie(evt) {

    $.ajax({
        url : core_params.ajaxurl,
        data : {
            'action': 'set_gdpr_cookie'
        },
        type : 'POST',
        beforeSend : function () {

            $(".gdprBlock").hide();

        }
    });

}
$(".gdprBlock .gdprClose").on("click tap", setGdprCookie);
